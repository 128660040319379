import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetShiftPolicyQuery } from 'pages/shift-management/shift-mangement-endpoints/shiftManagement.endpoints'
import { useMemo } from 'react'

// Define the Props and Filters interface for better type safety
interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  shifts: string[]
  dateBetween: number[]
  status: string[]
}

interface FormValues {
  dateBetween: number[]
  shifts: string[]
  status: string[]
}

// Status options for the status dropdown
const statusOptions = [
  { name: 'Pending', value: 'PENDING', _id: '101' },
  { name: 'Approved', value: 'APPROVED', _id: '102' },
  { name: 'Rejected', value: 'REJECTED', _id: '103' },
]

const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const FilterPanel: React.FC<Props> = ({ closePanel, setFilters, filters }) => {
  // Get selected plant from Redux state
  const plant = useSelector(selectSelectedItem)

  // Fetch shift policies based on the selected plant
  const { data: shiftPolicyList } = useGetShiftPolicyQuery(
    { plantId: plant?.id, filters: defaultFilters },
    { skip: !plant?.id },
  )

  // Initial form values based on current filters
  const initialValues: FormValues = {
    status: filters.status ?? [],
    shifts: filters.shifts ?? [],
    dateBetween: filters?.dateBetween?.length === 0 ? [] : filters?.dateBetween,
  }

  // Formik setup for form handling and submission
  const filterFormik = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        dateBetween: values.dateBetween || [],
        shifts: values.shifts || [],
        status: values.status || [],
      }))
    },
  })

  // Transform shift policies data into dropdown options
  const shiftOptions = useMemo(() => {
    return shiftPolicyList?.data?.flatMap((policy: any) =>
      policy.shifts.map((shift: any) => ({
        label: shift.shiftName,
        value: shift.shiftName,
      })),
    )
  }, [shiftPolicyList?.data])

  // Transform department and designation data into dropdown options

  const status = statusOptions.map((item) => ({
    label: item.name,
    value: item.value,
  }))

  // Handle reset functionality to clear filters
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      status: [],
      shifts: [],
      dateBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  return (
    <div className="flex h-full flex-col">
      {/* Content Area (Flex-grow so it takes the available space) */}
      <div className="flex flex-grow flex-col gap-3">
        {/* Date Range Field */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date"
          formik={filterFormik}
          name="dateBetween"
        />

        {/* Shift Field */}
        <Field
          type="multiselect"
          label="Shift"
          placeholder="Select Shift"
          options={shiftOptions}
          formik={filterFormik}
          name="shifts"
        />

        {/* Status Field */}
        <Field
          type="multiselect"
          label="Approval Status"
          placeholder="Select Status"
          options={status}
          formik={filterFormik}
          name="status"
        />
      </div>

      {/* Button actions for Reset, Cancel, and Apply (pinned at the bottom) */}
      <div className="pt-5">
        <div className="flex justify-between">
          <Button onClick={handleReset}>Reset</Button>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default FilterPanel
