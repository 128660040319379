/* eslint-disable react/prop-types */
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import WhiteCard from 'libs/card/WhiteCard'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetShiftPolicyQuery } from 'pages/shift-management/shift-mangement-endpoints/shiftManagement.endpoints'
import {
  useGetTimesheetByIDQuery,
  usePostTimesheetMutation,
  usePutTimesheetMutation,
} from 'pages/employee/time-sheet/timesheet-endpoints/timesheet.endpoints'
import { toast } from 'libs/toast'
import { useEffect } from 'react'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { formatUnixDateMultipleWithTimeZone } from 'utils/unixConverter'

const AddEditTimesheet = () => {
  const navigate = useNavigate()

  const plant = useSelector(selectSelectedItem)
  const { id } = useParams()

  const [addRecord, { isLoading: addRecordLoading }] = usePostTimesheetMutation()
  const [editRecord, { isLoading: editRecordLoading }] = usePutTimesheetMutation()
  const { data: timesheetData, isLoading: isTimesheetLoading } = useGetTimesheetByIDQuery(
    {
      plant_id: plant?.id,
      timesheetID: id,
    },
    {
      skip: !plant.id || !id,
    },
  )
  const filters = {
    page_no: 1,
    page_size: 1000,
    sortDir: 'DESC',
  }

  const { data: shiftPolicyList } = useGetShiftPolicyQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  const formik = useFormik({
    initialValues: {
      date: null,
      shiftName: '',
      policyID: '',
      description: '',
      startTime: '',
      endTime: '',
    },
    validationSchema: Yup.object({
      date: Yup.string().required('Date is required'),
      shiftName: Yup.string().required('Shift is required'),
      policyID: Yup.string().required('Shift is required'),
      startTime: Yup.string().required('Start Time is required'),
      endTime: Yup.string().required('End Time is required'),
    }),

    onSubmit: (values) => {
      const payload = {
        date: values.date,
        policyID: values.policyID,
        shiftName: values.shiftName,
        jobDescription: values.description,
        startTime: values.startTime,
        endTime: values.endTime,
      }

      if (!id) {
        addRecord({ plant_id: plant.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(<div>The timesheet record for <b>"Date {payload?.date && formatUnixDateMultipleWithTimeZone(payload?.date, plant?.timezone)}"</b> is added successfully.</div>
            )
            navigate('/timesheet')
          })
          .catch((error: any) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the Timesheet')
          })
      } else {
        editRecord({ plant_id: plant.id, timesheetID: id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(<div>The timesheet record for <b>"Date {payload?.date && formatUnixDateMultipleWithTimeZone(payload?.date, plant?.timezone)}"</b> is updated successfully.</div>
            )
            navigate('/timesheet')
          })
          .catch((error: any) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while updating the Timesheet')
          })
      }
    },
  })

  const policyOption = shiftPolicyList?.data?.map((policy: any) => ({
    _id: policy._id,
    label: policy.policyName,
    value: policy?._id,
  }))

  const policy = shiftPolicyList?.data?.find((policy) => policy._id === formik?.values?.policyID)
  const shiftOptions: any = (policy as any)?.shifts?.map((shift: any) => {
    return {
      label: shift?.shiftName,
      value: shift?.shiftName,
    }
  })

  const policyDefaultValue = policyOption?.find((option) => option?._id === formik?.values?.policyID)
  const defaultShift = { label: formik?.values?.shiftName } ?? { label: '' }
  useEffect(() => {
    if (timesheetData && id) {
      formik.resetForm({
        values: {
          date: timesheetData?.date ? timesheetData?.date : null,
          policyID: timesheetData?.shift_policy?._id,
          shiftName: timesheetData?.shiftName,
          description: timesheetData?.jobDescription,
          startTime: timesheetData?.startTime,
          endTime: timesheetData?.endTime,
        },
      })
    }
  }, [timesheetData, id])

  return (
    <>
      {!isTimesheetLoading ? (
        <div>
          <h1 className="h4-bold-black">{!id ? 'Add Record' : 'Edit Record'}</h1>
          <WhiteCard>
            <div className="flex min-h-[75vh] flex-col">
              <div className="flex flex-grow flex-col gap-3">
                <div>
                  <h5 className="h5-1">{'Record Details'}</h5>
                  <p className="p-sm-regular-2">
                    {!id
                      ? 'Log your work hours and ensure accurate tracking.'
                      : 'Edit the existing employee details to make necessary changes and updates.'}
                  </p>
                </div>

                <div className="grid max-w-[1200px] grid-cols-1 gap-3 md:grid-cols-2">
                  <Field
                    type="date"
                    required={true}
                    label={'Date'}
                    formik={formik}
                    name="date"
                    shouldDisableFutureDate={true}
                  />

                  <Field
                    type="select"
                    required={true}
                    label={'Policy'}
                    formik={formik}
                    options={policyOption}
                    name="policyID"
                    defaultValue={policyDefaultValue}
                    placeholder={'Select policy'}
                  />
                  <Field
                    type="select"
                    required={true}
                    label={'Shift'}
                    formik={formik}
                    options={shiftOptions}
                    name="shiftName"
                    placeholder={'Select shift'}
                    defaultValue={defaultShift}
                  />

                  <Field required type={'timepicker'} formik={formik} name="startTime" label="Start Time" />

                  <Field required type={'timepicker'} formik={formik} name="endTime" label="End Time" />
                </div>
                <div className="mt-4 border border-b border-border-medium"></div>
                <div className="max-w-[540px]">
                  <Field
                    type="textarea"
                    label={'Job Description'}
                    formik={formik}
                    name="description"
                    placeholder={'Enter description'}
                  />
                </div>

                <div className="mt-auto flex justify-end gap-3">
                  <Button type="button" onClick={() => navigate(-1)}>
                    {'Cancel'}
                  </Button>

                  <Button
                    loading={addRecordLoading || editRecordLoading}
                    color="success"
                    type="submit"
                    onClick={() => formik.handleSubmit()}
                  >
                    {!id ? 'Add' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
          </WhiteCard>
        </div>
      ) : (
        <FullPageLoader />
      )}
    </>
  )
}

export default AddEditTimesheet
