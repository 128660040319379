import { useMatches } from 'react-router-dom'
import { t } from 'i18next'

export const getDashboardInfo = () => {
  const matches = useMatches()
  let crumbs = matches[matches?.length - 1]

  switch (crumbs.handle) {
    //for production
    case 'production':
    case 'process':
    case 'entries':
    case 'add_entry':
    case 'edit_entry':
    case 'add/input':
    case 'review_add_input':
    case 'review_add_output':
    case 'add/output':
      return {
        label: 'Production',
        to: `/productionV2`,
      }
    //for review
    case 'review':
    case 'review_entries':
    case 'review_summary':
      return { label: 'Review', to: '/review' }
    case 'review_edit_entry':
      return {
        label: `${crumbs.params?.process ? crumbs.params?.department : ''}`,
        to: `/review`,
      }

    case 'new_processes':
    case 'preview':
      return { label: `Settings`, to: '/settings' }
    //for department

    case 'configure_department':
      return { label: 'Settings', to: '/settings/configure' }
    //for products
    case 'configure_products':
    case 'plants':
    case 'configure_products_new':
    case 'configure_products_edit':
      return { label: 'Settings', to: '/settings/configure' }
    //for settings
    case 'configure_process':
      return { label: `Settings`, to: '/settings' }
    //for planning

    case 'production_planning':
    case 'production_plans':
    case 'new_plan':
    case 'edit_plan':
    case 'select_plan':
    case 'work_orders':
    case 'add_work_order':
    case 'edit_work_order':
    case 'view_work_order':
    case 'manufacture_orders':
    case 'new_manufacture_order':
    case 'view_manufacture_order':
    case 'edit_manufacture_order':
      return { label: 'Planning', to: '/planning' }

    case 'configure_warehouses':
    case 'new_warehouses':
    case 'edit_warehouses':
    case 'view_warehouses':
      return { label: 'Settings', to: '/settings/configure' }

    //inventory

    case 'inventory':
    case 'inventory_new':
    case 'inventory_transaction_history':
    case 'inventory_transaction_history_edit':
      return { label: t('inventory_.inventory'), to: '/inventory' }

    // //Plants
    // case 'plants':
    //   return { label: 'Plants', to: '/plants'}
    case 'order':
    case 'sales_order':
    case 'purchase_order':
    case 'sales_order_new':
    case 'edit_purchase_order':
    case 'view_purchase_order':
    case 'add_purchase_order':
    case 'edit_sales_order':
    case 'view_sales_order':
      return { label: 'Orders', to: '/orders' }

    case 'dashboard':
    case 'inventory_dashboard':
    case 'workorder_dashboard':
    case 'manufacture_dashboard':
      return { label: 'Dashboard', to: '/dashboard' }

    //mo

    case 'employee':
    case 'time_sheets':
    case 'add_employee':
    case 'employee_management':
    case 'edit_employee':
    case 'view_employee':
    case 'add_sheet':
    case 'edit_sheet':
      return { label: 'Employee', to: '/employee' }
    // contacts
    // customer
    case 'contact':
    case 'customer':
    case 'add_customer':
    case 'edit_customer':
      return { label: 'Contacts', to: '/contacts' }
    // vendor
    case 'vendor_management':
    case 'edit_vendor':
    case 'view_vendor':
    case 'add_vendor':
    case 'view_customer':
      return { label: 'Contacts', to: '/contacts' }

    //employee department

    case 'employee_department':
    case 'add_employee_department':
    case 'add_employee_designation':
    case 'view_employee_designation':
      return { label: 'Employee', to: '/employee' }

    // user management

    case 'users':
    case 'roles':
    case 'add_roles':
    case 'edit_roles':
    case 'view_roles':
      return { label: 'Settings', to: '/settings' }

    // shift management

    case 'add_shift_policy':
    case 'edit_shift_policy':
    case 'view_shift_policy':
      return { label: 'Settings', to: '/settings/shift-management' }

    //operator timesheet
    case 'edit_operator_timesheet':
    case 'add_operator_timesheet':
      return { label: 'TimeSheets', to: '/timesheet' }

    case 'maintenance':
    case 'maintenance_order':
    case 'add_maintenance_order':
    case 'view_maintenance_order':
    case 'maintenance_order':
    case 'maintenance_request':
    case 'add_maintenance_request':
    case 'view_maintenance_request':
    case 'edit_maintenance_request':
    case 'edit_maintenance_order':
    case 'link_maintenance_request':
      case 'add_link_maintenance_request':
      return { label: 'Maintenance', to: '/maintenance' }

    default:
      return { label: '', to: '' }
  }
}

export const getPreviousInfo = () => {
  const matches = useMatches()
  let crumbs = matches[matches?.length - 1]

  switch (crumbs.handle) {
    //for production
    case 'entries':
    case 'add_entry':
    case 'edit_entry':
    case 'add/input':
    case 'add/output':
      return {
        label: `${crumbs.params?.process ? crumbs.params?.department : ''}`,
        to: `/productionV2/${crumbs.params?.department}/${crumbs.params?.process}`,
      }
    //for review
    case 'review_entries':
      return {
        label: `${crumbs.params?.process ? crumbs.params?.department : ''}`,
        to: '/review',
      }
    case 'review_edit_entry':
      return {
        label: `${crumbs.params?.process ? 'process' : ''}`,
        to: `/review/${crumbs.params?.department}/${crumbs.params?.process}`,
      }
    case 'review_summary':
      return {
        label: `${crumbs.params?.department ? crumbs.params?.department : ''}`,
        to: '/review',
      }

    case 'review_add_input':
    case 'review_add_output':
      return {
        label: `${crumbs.params?.process ? 'Process' : ''}`,
        to: `/review/${crumbs.params?.department}/${crumbs.params?.process}`,
      }

    //for planning

    case 'new_plan':
    case 'edit_plan':
    case 'select_plan':
      return { label: 'Production Plans', to: '/planning/production-plans' }
    //for configure
    case 'configure_products_new':
    case 'configure_products_edit':
      return { label: t('products'), to: '/settings/configure/products' }

    case 'plants':
    case 'configure_products':
      return { label: 'Configure', to: '/settings/configure' }

    case 'view_work_order':
      return { label: 'Work Orders', to: '/planning/work-orders' }
    case 'add_work_order':
      return { label: 'Work Orders', to: '/planning/work-orders' }
    case 'edit_work_order':
      return { label: 'Work Orders', to: '/planning/work-orders' }

    //for department

    case 'new_warehouses':
    case 'edit_warehouses':
    case 'view_warehouses':
      return { label: t('warehouse.warehouses'), to: '/settings/configure/warehouses' }

    // for inventory
    case 'inventory_transaction_history_edit':
      return {
        label: t('inventory_.inventory_transaction_history'),
        to: -1,
      }
    case 'preview':
    case 'new_processes':
      return { label: 'Operational Processes', to: '/settings/configure/operationalprocesses' }

    case 'configure_department':
      return { label: 'Configure', to: '/settings/configure' }
    case 'configure_process':
      return { label: `Configure`, to: '/settings' }
    case 'configure_warehouses':
      return { label: 'Configure', to: '/settings/configure' }

    //mo

    case 'new_manufacture_order':
    case 'edit_manufacture_order':
    case 'view_manufacture_order':
      return { label: 'Manufacturing Orders', to: '/planning/mo' }

    // for SalesOrder

    case 'sales_order_new':
    case 'view_sales_order':
    case 'edit_sales_order':
      return { label: 'Sales Orders', to: '/orders/sales-order' }

    case 'add_purchase_order':
    case 'view_purchase_order':
    case 'edit_purchase_order':
      return { label: 'Purchase Order', to: '/orders/purchase-order' }

    case 'add_employee':
    case 'edit_employee':
    case 'view_employee':
      return { label: 'Employee Directory', to: '/employee/employee-management' }

    case 'add_customer':
    case 'edit_customer':
    case 'view_customer':
      return { label: 'Customers ', to: '/contacts/customer-management' }
    case 'add_vendor':
    case 'edit_vendor':
    case 'view_vendor':
      return { label: 'Vendors ', to: '/contacts/vendor-management' }

    //employee department

    case 'add_employee_department':
    case 'add_employee_designation':
    case 'view_employee_designation':
      return { label: 'Employee Roles', to: '/employee/employee-department' }

    //dashboard

    // user management
    case 'users':
    case 'roles':
      return { label: 'User Management', to: '/settings/user-management' }
    case 'add_roles':
    case 'edit_roles':
    case 'view_roles':
      return { label: 'User Roles', to: '/settings/user-management/roles' }

    // Shift Management
    case 'add_shift_policy':
    case 'edit_shift_policy':
    case 'view_shift_policy':
      return { label: 'Shift Management', to: '/settings/shift-management' }

    case 'add_sheet':
    case 'edit_sheet':
      return { label: 'Timesheets', to: '/employee/time-sheet' }

    //Maintenance

    case 'maintenance_order':
    case 'add_maintenance_order':
    case 'edit_maintenance_order':
    case 'view_maintenance_order':
      case'add_link_maintenance_request':
      return { label: 'Maintenance Orders', to: '/maintenance/maintenance-order' }

    case 'maintenance_request':
    case 'add_maintenance_request':
    case 'view_maintenance_request':
    case 'edit_maintenance_request':
      case 'link_maintenance_request':
      return { label: 'Maintenance Requests', to: '/maintenance/maintenance-request' }

    default:
      return { label: '', to: '' }
  }
}

export const getCurrentInfo = () => {
  const matches = useMatches()
  let crumbs = matches[matches?.length - 1]
  const value = crumbs.pathname.includes('/editdetails')

  switch (crumbs.handle) {
    //for production
    case 'process':
      return { label: `${crumbs.params?.department}` }
    case 'entries':
      return { label: `Process` }
    case 'add/input':
      return { label: `Add Input` }
    case 'add/output':
      return { label: `Add Output` }

    case 'add_entry':
      return { label: `Add entry` }
    case 'edit_entry':
      return { label: `Edit entry` }
    //for review
    case 'review_summary':
      return { label: `Summary` }
    case 'review_entries':
      return { label: `Process` }
    case 'review_add_input':
      return { label: `Add Input` }
    case 'review_add_output':
      return { label: `Add Output` }
    case 'review_edit_entry':
      return { label: `Edit entry` }
    //for configure
    case 'configure_newProcess':
      return {
        label: `${
          crumbs.params?.process === 'newoperationalprocesses' ? 'New operational process' : crumbs.params?.process
        }`,
      }
    case 'configure_editProcess':
      return { label: `${crumbs.params?.process}` }
    case 'configure_process':
      return {
        label: `${crumbs.handle === 'configure_process' ? 'Operational processes' : ''}`,
      }

    // case 'configure_newProcess':
    // case 'configure_editProcess':
    case 'new_processes':
      return {
        label: `${value ? 'Edit Operational Processes' : 'New Operational Processes'}`,
      }
    case 'configure_products':
      return { label: t('products') }
    case 'configure_products_new':
      return { label: t('product.add_new_product') }
    case 'configure_products_edit':
      return { label: t('product.edit_product') }

    //for department
    case 'configure_department':
      return {
        label: 'Department',
      }

    // for Plants
    case 'plants':
      return {
        label: 'Plants',
      }

    case 'new_plan':
      return {
        label: 'New plan',
      }

    case 'edit_plan':
    case 'select_plan':
      return { label: `${crumbs.params?.id}` }

    case 'new_warehouses':
      return {
        label: t('warehouse.new_warehouse'),
      }
    case 'view_warehouses':
      return {
        label: t('warehouse.warehouse_details'),
      }
    case 'edit_warehouses':
      return {
        label: t('warehouse.edit_warehouse'),
      }
    case 'configure_warehouses':
      return {
        label: t('warehouse.warehouses'),
      }
    case 'preview':
      return {
        label: 'Preview',
      }

    case 'work_orders':
      return { label: 'Work Orders' }
    case 'add_work_order':
      return { label: 'Add Work Order' }
    case 'edit_work_order':
      return { label: 'Edit Work Order' }
    case 'view_work_order':
      return { label: 'View Work Order' }
    //inventory
    case 'inventory_new':
      return {
        label: t('inventory_.new_inventory_transaction'),
      }
    case 'inventory_transaction_history':
      return {
        label: t('inventory_.inventory_transaction_history'),
      }
    case 'inventory_transaction_history_edit':
      return {
        label: t('inventory_.edit_inventory'),
      }
    case 'production_plans':
      return { label: 'Production Plans' }
    // sales order
    case 'sales_order':
      return { label: 'Sales Orders' }
    case 'sales_order_new':
      return { label: 'Add Sales Order' }
    case 'edit_sales_order':
      return { label: 'Edit Sales Order' }
    case 'view_sales_order':
      return { label: 'Sales Order Details' }

    // purchase order
    case 'purchase_order':
      return { label: 'Purchase Order' }
    case 'add_purchase_order':
      return { label: 'Add Purchase Order' }
    case 'edit_purchase_order':
      return { label: 'Edit Purchase Order' }
    case 'view_purchase_order':
      return { label: 'Purchase Order Details' }
    //mo
    case 'new_manufacture_order':
      return { label: 'Add Manufacturing Order' }
    case 'edit_manufacture_order':
      return { label: 'Edit Manufacturing Order' }

    case 'view_manufacture_order':
      return { label: 'Manufacturing Orders', to: '/planning/mo' }

    case 'manufacture_orders':
      return { label: 'Manufacturing Order Detail' }

    case 'add_employee':
      return { label: 'Add New Employee ', to: '' }
    case 'edit_employee':
      return { label: 'Edit Employee ', to: '' }

    case 'employee_management':
      return { label: 'Employee Directory', to: '/employee/employee-management' }

    case 'view_employee':
      return { label: 'View Employee Details ', to: '' }
    //  contacts
    // customer
    case 'customer':
      return { label: 'Customers ', to: '' }

    case 'dashboard':
      return { label: 'Dashboard', to: '' }

    case 'add_customer':
      return { label: 'Add New Customer ', to: '' }

    case 'edit_customer':
      return { label: 'Edit Customer ', to: '' }
    case 'view_customer':
      return { label: 'view Customer ', to: '' }

    // vendors
    case 'vendor_management':
      return { label: 'Vendor' }
    case 'add_vendor':
      return { label: 'Add New Vendor' }
    case 'view_vendor':
      return { label: 'View Vendor Details' }
    case 'edit_vendor':
      return { label: 'Edit Vendor' }

    case 'employee_department':
      return { label: 'Employee Roles', to: '' }
    case 'add_employee_department':
      return { label: 'Manage Department', to: '' }
    case 'add_employee_designation':
      return { label: 'Add Designations', to: '' }
    case 'view_employee_designation':
      return { label: 'View Department', to: '' }

    case 'inventory_dashboard':
      return { label: 'Inventory Dashboard' }

    case 'workorder_dashboard':
      return { label: 'Work Order Reports' }

    case 'manufacture_dashboard':
      return { label: 'Manufacture Order Reports' }

    // user management
    case 'users':
      return { label: 'Users' }
    case 'roles':
      return { label: 'User Roles', to: '' }
    case 'add_roles':
      return { label: 'Add User Role', to: '' }
    case 'edit_roles':
      return { label: 'Edit User Role', to: '' }
    case 'view_roles':
      return { label: 'View User Role', to: '' }

    case 'time_sheets':
      return { label: 'TimeSheets' }

    case 'add_sheet':
      return { label: 'Add Record' }
    case 'edit_sheet':
      return { label: 'Edit Record' }

    // shift management
    case 'add_shift_policy':
      return { label: 'Add Shift Policy', to: '/settings/shift-management' }
    case 'edit_shift_policy':
      return { label: 'Edit Shift Policy', to: '/settings/shift-management' }
    case 'view_shift_policy':
      return { label: 'View Shift Policy', to: '/settings/shift-management' }

    //operator timesheet

    case 'add_operator_timesheet':
      return { label: 'Add Record' }
    case 'edit_operator_timesheet':
      return { label: 'Edit Record' }
    //maintenanace

    case 'add_maintenance_order':
      return { label: 'Create Maintenance Order' }

    case 'view_maintenance_order':
      return { label: 'View Maintenance Order' }
    case 'edit_maintenance_request':
      return { label: 'Edit Maintenance Request' }
    case 'view_maintenance_request':
      return { label: 'View Maintenance Request' }
    case 'add_maintenance_request':
      return { label: 'Add Maintenance Request' }
      case 'edit_maintenance_order':
        return { label: 'Edit Maintenance Order' }
      case 'link_maintenance_request':
        return {label:'Link Maintenance Requests'}
        case 'add_link_maintenance_request':
          return {label:'Create Maintenance Order'}
    default:
      return { label: '' }
  }
}
